import React from 'react'
import Layout from "../layouts"
const PolitykaPrywatnosci = () => {

    return (
        <Layout>
            <h1>Polityka Prywatnosci</h1>
        </Layout>
    )
}

export default PolitykaPrywatnosci